<template>
  <div class="basket-desktop">
    <span class="basket__link">
      <img class="basket__icon" :src="require('@javascript/packs/images/@priorbank/bascket.png')" />
      <div class="basket__count">{{ cartTotalCount }}</div>
    </span>
    <div class="basket__list-item">
      <cart
        :locale="locale"
        :defaultLocale="defaultLocale"
        :userId="userId"
        :base-url-with-locale="baseUrlWithLocale"
      />
    </div>
  </div>
</template>

<script>
import Cart from '@components/@priorbank/cart'

export default {
  name: 'CartDesktop',
  components: {
    Cart,
  },
  props: {
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    userId: {
      type: [String, Number],
      default: '',
    },
    cartTotalCount: {
      type: [String, Number],
      default: 0,
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
  },
}
</script>
