<template>
  <div class="footer-bottom container">
    <vl-information-static-block
      class="mb-4"
      :text="renderUserTokenAndTextCallCenter"
    />
    <div class="row">
      <div class="col-24 col-md-12 order-2 order-md-1 mt-3 mt-md-0 licens">
        <p>&copy; Copyright {{renderCurrentYear}}</p>
      </div>
      <div class="col-24 col-md-12 order-1 order-md-2 social-networks">
        <a
          class="social-networks__item"
          target="_blank"
          rel="noopener noreferrer"
          v-for="(item, index) in socialNetworks"
          :key="index"
          :href="item.link"
        >
          <img :src="item.logoSrc" alt="socialNetwork" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import VlButton from '@components/@priorbank/ui/vl-button'
import VlInformationStaticBlock from '@components/@priorbank/ui/vl-information-static-block'
import locales from '../locales'
export default {
  name: 'FooterBottom',
  components: { VlButton, VlInformationStaticBlock },
  props: {
    locale: {
      type: String,
      default: '',
    },
    socialNetworks: {
      type: Array,
      default: [],
    },
    footerCopyright: {
      type: String,
      default: null,
    },
    userToken: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      t: locales,
    }
  },
  computed: {
    renderUserTokenAndTextCallCenter() {
      return `${this.t[locale]['footer.information_about_callcenter']} ${this.userToken}`
    },
    renderCurrentYear() {
      return new Date().getFullYear()
    },
  },
}
</script>
