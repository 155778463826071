var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'vl-textarea vl-wrapper',
    {
      'on-color-bg': _vm.onColorBg,
      'light-mode': _vm.lightMode
    },
  ]},[_c('fieldset',{class:[
      'vl-title',
      {
        'vl-title--active': _vm.isActive,
        'vl-title--filled': _vm.isFilled,
        'vl-title--placeholder': _vm.isPlaceholder,
        'vl-title--disabled': _vm.disabled,
        'vl-title--error': _vm.error,
      },
    ],attrs:{"aria-hidden":"true"}},[_c('legend',{ref:"legend"},[_c('span',[_vm._v(" ")])])]),_vm._v(" "),_c('div',{staticClass:"vl-input-wrapper"},[_c('textarea',{ref:"input",staticClass:"vl-input",attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"name":_vm.name,"autocomplete":_vm.autocomplete,"rows":_vm.rows,"type":_vm.type},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":_vm.onChangeValue,"blur":_vm.blur,"focus":_vm.focus}}),_vm._v(" "),_c('label',{class:[
        'vl-label',
        {
          'vl-label--active-label': _vm.isActiveLabel,
        },
      ]},[_c('span',{ref:"label",class:{
          'vl-label--active': _vm.isActive,
          'vl-label--filled': _vm.isFilled,
          'vl-label--placeholder': _vm.isPlaceholder,
          'vl-label--disabled': _vm.disabled,
          'vl-label--error': _vm.error,
        }},[_vm._v(_vm._s(_vm.label))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }