<template>
  <div
    v-if="showNotifications"
    class="vl-notification-static"
    :style="fullWidth ? fullWidthStyle : ''"
  >
    <vl-notification-message
      :notification-data="notification"
      :title-translate="title"
      @closeMessage="closeMessage"
    />
  </div>
</template>
<script>
import VlNotificationMessage from './components/vl-notification-message/VlNotificationMessage'
import { mapMutations, mapGetters } from 'vuex'
import locales from './locales'
export default {
  name: 'VlNotification',
  components: {
    VlNotificationMessage,
  },
  inject: ['locale', 'defaultLocale'],
  props: {
    propNotification: null,
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      t: locales,
    }
  },
  methods: {
    ...mapMutations({
      clearStaticMessage: 'notification/clearStaticMessage',
      setStaticNotification: 'notification/setStaticNotification',
    }),
    closeMessage() {
      this.clearStaticMessage()
    },
    setNotification(notification) {
      if (notification && notification.description) {
        this.setStaticNotification(notification)
      }
    },
  },
  computed: {
    ...mapGetters({
      notification: 'notification/staticNotification',
    }),
    showNotifications() {
      return !!this.notification
    },
    fullWidthStyle() {
      return {
        width: '100%',
      }
    },
    title() {
      return this.t[locale]['notifications.error_title']
    },
  },
  created() {
    this.setNotification(this.propNotification)
  },
}
</script>
