<template>
  <div class="col-auto d-flex align-items-center">
    <div class="d-flex flex-md-row align-items-center">
      <div class="header__user-balance">
        {{ t[locale]['priorbank.header.header_botom_menu.your_balance'] }}
        <span>{{ balance }}</span>
        {{ currency }}

        <div class="bonuses-expire" v-if="userData.bonuses_will_expire > 0">
          <vl-tooltip :isCloseBtn="true">
            <template #activator="on">
              <div v-on="on" class="d-flex">
                <vl-icon-base width="14" height="14" viewBoxWidth="14" viewBoxHeight="14" opacity="1">
                  <vl-icon-exclamation-mark />
                </vl-icon-base>
              </div>
            </template>
            <template>
              <div>
                {{ t[locale]['profile.my_balance.bonuses_can_expire'].replace('{bonuses}', userData.bonuses_will_expire) }}
                <a :href="rulesPage" target="_blank">{{ t[locale]['profile.my_balance.rules'] }}</a>.
              </div>
            </template>
          </vl-tooltip>
        </div>

      </div>
      <cart
        v-if="!isOrderPage"
        :locale="locale"
        :default-locale="defaultLocale"
        :user-id="userData.id"
        :base-url-with-locale="baseUrlWithLocale"
      />
    </div>
  </div>
</template>
<script>
import Cart from '../../cart/Cart'
import VlTooltip from '@components/@priorbank/ui/vl-tooltip'
import VlIconBase from '@components/@priorbank/ui/vl-icons'
import VlIconExclamationMark from '@components/@priorbank/ui/vl-icons/VlIconeExclamationMark'
import { ORDER_SHOW } from '@javascript/config/routes'

import locales from './locales'

export default {
  name: 'BottomMenuLoggedIn',
  components: {
    Cart,
    VlTooltip,
    VlIconBase,
    VlIconExclamationMark,
  },
  props: {
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    userData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      t: locales,
    }
  },
  computed: {
    balance() {
      return this.userData?.balance
    },
    currency() {
      return this.userData?.site_currency
    },
    isOrderPage() {
      // return window.location.pathname.includes(ORDER_SHOW)
      const orderRouter = `${this.baseUrlWithLocale}${ORDER_SHOW}`
      return window.location.pathname == orderRouter
    },
    rulesPage() {
      return `${this.baseUrlWithLocale}/topic/rules`
    },
  },
}
</script>
