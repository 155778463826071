<template>
  <div
    :class="[
      'vl-textarea vl-wrapper',
      {
        'on-color-bg': onColorBg,
        'light-mode': lightMode
      },
    ]"
  >
    <fieldset
      :class="[
        'vl-title',
        {
          'vl-title--active': isActive,
          'vl-title--filled': isFilled,
          'vl-title--placeholder': isPlaceholder,
          'vl-title--disabled': disabled,
          'vl-title--error': error,
        },
      ]"
      aria-hidden="true"
    >
      <legend ref="legend">
        <span>&#160;</span>
      </legend>
    </fieldset>
    <div class="vl-input-wrapper">
      <textarea
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :name="name"
        :autocomplete="autocomplete"
        :rows="rows"
        class="vl-input"
        :type="type"
        ref="input"
        @input="$emit('input', $event.target.value)"
        @change="onChangeValue"
        @blur="blur"
        @focus="focus"
      />
      <label
        :class="[
          'vl-label',
          {
            'vl-label--active-label': isActiveLabel,
          },
        ]"
      >
        <span
          :class="{
            'vl-label--active': isActive,
            'vl-label--filled': isFilled,
            'vl-label--placeholder': isPlaceholder,
            'vl-label--disabled': disabled,
            'vl-label--error': error,
          }"
          ref="label"
        >{{ label }}</span>
      </label>
    </div>
  </div>
</template>
<script>
import fieldsetV2 from '@javascript/mixins/fieldsetV2'
export default {
  name: 'VlInputText',
  mixins: [fieldsetV2],
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator: val =>
        ['text', 'tel', 'email', 'password', 'hidden'].includes(val),
    },
    label: {
      type: String,
      default: 'Input text',
    },
    error: {
      type: Boolean,
      default: false,
    },
    onColorBg: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    lightMode: {
      type: Boolean,
      default: false,
    },
    showIconError: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: [String, Number],
      default: 5,
    },
  },
  methods: {
    onChangeValue(evt) {
      this.$emit('change', evt.target.value)
    },
  },
}
</script>
