<template>
  <div class="menu-logged-in">
    <vl-button size="medium" type="secondary" @click.native.stop="toggleLoginBar">
      <span class="header__user-greeting">
        {{ t[locale]['header.greeting'] }}
        <span>, {{ userName }}</span>
      </span>
    </vl-button>
    <popap v-if="showLoginBar" v-click-outside="toggleLoginBar">
      <ul class="header-context-menu">
        <li v-for="item of profileMenu" :key="item.id" class="header-context-menu__item">
          <a class="header-context-menu__url" :href="item.url">{{ item.title }}</a>
        </li>
        <li class="header-context-menu__item" @click="exitHandler">
          <span class="header-context-menu__url">
            {{
            t[locale]['authorization.logout.exit']
            }}
          </span>
        </li>
      </ul>
    </popap>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import VlButton from '@components/@exim/ui/vl-button'
import Popap from '../../popap'
import locales from '../locales'
export default {
  name: 'MenuDesktopLoggedIn',
  components: {
    VlButton,
    Popap,
  },
  props: {
    userName: {
      type: String,
      default: false,
    },
    profileMenu: {
      type: Array,
      default: null,
    },
    locale: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    t: locales,
    showLoginBar: false,
  }),
  methods: {
    ...mapActions({
      API_LOGOUT: 'authorization/API_LOGOUT',
    }),
    exitHandler() {
      this.API_LOGOUT()
    },
    toggleLoginBar() {
      this.showLoginBar = !this.showLoginBar
    },
  },
}
</script>
