 <template>
  <g>
    <circle cx="15" cy="15" r="14.5" :stroke="stroke" />
    <path d="M18.5234 15.1797H12V14H18.5234V15.1797Z" :fill="fill" />
  </g>
</template>
<script>
export default {
  name: 'VlIconPlus',
  props: {
    fill: {
      type: [String, Array],
      default: '#999999',
    },
    stroke: {
      type: [String, Array],
      default: '#999999',
    },
  },
}
</script>
