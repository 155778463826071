 <template>
  <g>
    <circle cx="15" cy="15" r="14.5" :stroke="stroke" />
    <path
      d="M15.625 14.3203H18.7969V15.6875H15.625V19.2812H14.1719V15.6875H11V14.3203H14.1719V11H15.625V14.3203Z"
      :fill="fill"
    />
  </g>
</template>
<script>
export default {
  name: 'VlIconPlus',
  props: {
    fill: {
      type: [String, Array],
      default: '#999999',
    },
    stroke: {
      type: [String, Array],
      default: '#999999',
    },
  },
}
</script>
