<template>
  <div class="cart-item">
    <div class="cart-item__header">
      <div class="cart-item__title">{{ product.title }}</div>
      <vl-button
        class="cart-item__remove-action d-flex"
        type="inline"
        size="inline"
        @click.native="removeProductFromCart(product)"
      >
        <vl-icon-base
          width="17"
          height="17"
          viewBoxWidth="21"
          viewBoxHeight="21"
          iconColor="#999999"
        >
          <vl-icon-remove />
        </vl-icon-base>
      </vl-button>
    </div>
    <div class="cart-item__active-block">
      <div class="cart-item__calc w-100">
        <vl-button type="inline" size="inline" @click.native="decrementProductHandler(product)">
          <vl-icon-base
            iconColor="transparent"
            width="30"
            height="30"
            viewBoxWidth="30"
            viewBoxHeight="30"
          >
            <vl-icon-minus
              :fill="[buttonRemoveDisabled ? '#DFE1E0' : '#999999']"
              :stroke="[buttonRemoveDisabled ? '#DFE1E0' : '#999999']"
            />
          </vl-icon-base>
        </vl-button>
        <input type="text" class="cart-item__calc-input" readonly :value="product.quantity" />
        <vl-button type="inline" size="inline" @click.native="addProductHandler(product)">
          <vl-icon-base
            iconColor="transparent"
            width="32"
            height="33"
            viewBoxWidth="32"
            viewBoxHeight="33"
          >
            <vl-icon-plus
              :fill="[buttonAddDisabled ? '#DFE1E0' : '#999999']"
              :stroke="[buttonAddDisabled ? '#DFE1E0' : '#999999']"
            />
          </vl-icon-base>
        </vl-button>
        <div class="cart-item__cost ml-auto">
          {{ product.sitePrice }}
          <span class="cart-item__site-currency">
            {{ product.sitePrice | serializePoint }}
          </span>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import VlButton from '@components/@priorbank/ui/vl-button'
import VlIconBase from '@components/@priorbank/ui/vl-icons'
import VlIconRemove from '@components/@priorbank/ui/vl-icons/VlIconRemove'
import VlIconPlus from '@components/@priorbank/ui/vl-icons/VlIconPlus'
import VlIconMinus from '@components/@priorbank/ui/vl-icons/VlIconMinus'

export default {
  name: 'CartItem',
  components: {
    VlButton,
    VlIconBase,
    VlIconRemove,
    VlIconPlus,
    VlIconMinus,
  },
  props: {
    product: {
      type: Object,
      default: {},
    },
  },
  data: () => ({
    minimumOrderQuantity: 1,
  }),
  computed: {
    buttonRemoveDisabled() {
      return this.product.quantity <= this.minimumOrderQuantity
    },
    buttonAddDisabled() {
      return this.product.quantity >= this.product.stockCount
    },
  },
  methods: {
    ...mapActions({
      addProductToCart: 'cart/addProductToCart',
      removeProductFromCart: 'cart/removeProductFromCart',
    }),
    ...mapMutations({
      decrementItemQuantity: 'cart/decrementItemQuantity',
    }),
    decrementProductHandler(product) {
      if (!this.buttonRemoveDisabled) {
        this.decrementItemQuantity(product)
      }
    },
    addProductHandler(product) {
      if (!this.buttonAddDisabled) {
        this.addProductToCart(product)
      }
    },
  },
}
</script>
