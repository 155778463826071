<template>
  <div class="popap-container">
    <div class="popap-container__arrow-up"></div>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Popap',
}
</script>
