<template>
  <footer class="footer">
    <footer-top :phones="footerPhone" :locale="locale" />
    <hr class="mt-0 mb-0" />
    <footer-middle
      :first-menu="footerMenu"
      :second-menu="secondMenu"
      :email="footerEmail"
      :locale="locale"
    />
    <footer-bottom
      :locale="locale"
      :social-networks="socialNetworks"
      :footer-copyright="footerCopyright"
      :user-token="userToken"
    />
  </footer>
</template>

<script>
import FooterTop from './footer-top'
import FooterMiddle from './footer-middle'
import FooterBottom from './footer-bottom'
import locales from './locales'
export default {
  name: 'Footer',
  components: {
    FooterTop,
    FooterMiddle,
    FooterBottom,
  },
  props: {
    footerMenu: {
      type: Object,
      default: null,
    },
    footerCopyright: {
      type: String,
      default: null,
    },
    footerPhone: {
      type: Array,
      default: [],
    },
    footerEmail: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: '',
    },
    userToken: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      t: locales,
      secondMenu: [
        {
          url: 'https://www.priorbank.by/offers/services/currency-exchange',
          title:
            locales[this.locale][
              'priorbank.footer.information_menu.menus_item.currency'
            ],
        },
        {
          url: 'https://www.prior.by/maps',
          title:
            locales[this.locale][
              'priorbank.footer.information_menu.menus_item.branches'
            ],
        },
        {
          url: 'https://www.priorbank.by/priorbank-main/press-center/news',
          title:
            locales[this.locale][
              'priorbank.footer.information_menu.menus_item.news'
            ],
        },
        {
          url: 'https://www.priorbank.by/offers/importantly/financial-literacy',
          title:
            locales[this.locale][
              'priorbank.footer.information_menu.menus_item.regulations'
            ],
        },
      ],
      socialNetworks: [
        {
          logoSrc: require('@javascript/packs/images/@priorbank/footer/instagram.png'),
          link: 'https://www.instagram.com/priorbankby/',
        },
        {
          logoSrc: require('@javascript/packs/images/@priorbank/footer/twiter.png'),
          link: 'https://twitter.com/priorbankBY',
        },
        {
          logoSrc: require('@javascript/packs/images/@priorbank/footer/facebook.png'),
          link: 'https://www.facebook.com/Priorbank',
        },
        {
          logoSrc: require('@javascript/packs/images/@priorbank/footer/vk.png'),
          link: 'https://vk.com/priorbank_rbi',
        },
        {
          logoSrc: require('@javascript/packs/images/@priorbank/footer/viber.png'),
          link: 'https://chats.viber.com/priorbank',
        },
        {
          logoSrc: require('@javascript/packs/images/@priorbank/footer/telegram.png'),
          link: 'https://t.me/Priorbank_official_bot',
        },
      ],
    }
  },
}
</script>
