<template>
  <transition name="vl-modal-fade">
    <div v-if="open" class="vl-modal" @click="closeOutside ? close() : ''">
      <div
        @click.stop
        :class="['vl-modal__content', , { 'vl-modal__content--with-left-image': withLeftImage }]"
        :style="{
          marginTop: top + 'px',
          maxWidth: maxWidth + 'px',
          flexBasis: maxWidth + 'px',
          textAlign: contentAlign,
        }"
      >
        <!-- <button
          v-if="outside"
          :class="['vl-modal__cancel', { 'vl-modal__cancel--outside': outside }]"
          @click.stop="close"
        >
          <span></span>
        </button> -->
        <div class="vl-modal__header">
          <div class="container-fluid px-0">
            <div class="row no-gutters">
              <div class="col-24 d-flex justify-content-end align-items-center mb-3">
                <button v-if="!outside" class="vl-modal__cancel" @click.stop="close">
                  <span></span>
                </button>
              </div>
              <div
                class="col-24 d-flex align-items-center"
                :style="{
                  'justify-content': titleAlign === 'center' ? titleAlign : `${titleAlign}`,
                }"
              >
                <div class="vl-modal__img-wrapper" v-if="titleWithSuccessIcon">
                  <img :src="require('@javascript/packs/images/@privat/modal/icon-success.png')" alt="success icon" />
                </div>
                <h3
                  class="vl-modal__title"
                  :style="{
                    textTransform: titleTransform,
                  }"
                >
                  <slot name="header" />
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div
          ref="modalBody"
          :class="['vl-modal__body', { scroll: staticScroll }]"
          :style="{
            marginTop: contentOutsideTop + 'px',
            marginBottom: contentOutsideBottom + 'px',
          }"
        >
          <div class="vl-modal__description">
            <slot />
          </div>
        </div>
        <div
          class="vl-modal__bottom"
          :style="{
            marginTop: footerOffsetTop + 'px',
          }"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'VlModal',
  props: {
    contentOutsideTop: {
      type: Number,
      default: null,
    },
    contentOutsideBottom: {
      type: Number,
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: [String, Number],
      default: 360,
    },
    top: {
      type: [String, Number],
      default: 0,
    },
    titleTransform: {
      type: String,
      default: 'none',
    },
    titleAlign: {
      type: String,
      default: 'start',
    },
    contentAlign: {
      type: String,
      default: 'initial',
    },
    footerOffsetTop: {
      type: Number,
      default: 30,
    },
    titleWithSuccessIcon: {
      type: Boolean,
      default: false,
    },
    withLeftImage: {
      type: Boolean,
      default: false,
    },
    outside: {
      type: Boolean,
      default: false,
    },
    staticScroll: {
      type: Boolean,
      default: false,
    },
    closeOutside: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    addScroll() {
      let selectList = this.$refs.modalBody
      let height = 0

      if (selectList) {
        height = parseInt(window.getComputedStyle(selectList).height)
      }
      if (height > 300) {
        selectList.classList.add('scroll')
      }
    },
  },
  created() {
    document.addEventListener('keydown', (e) => {
      if (this.open && e.keyCode == 27) {
        this.close()
      }
    })
  },
  mounted() {
    if (!this.staticScroll) this.addScroll()
  },
  updated() {
    this.addScroll()
  },
}
</script>
