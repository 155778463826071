var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"vl-modal-fade"}},[(_vm.open)?_c('div',{staticClass:"vl-modal",on:{"click":function($event){_vm.closeOutside ? _vm.close() : ''}}},[_c('div',{class:['vl-modal__content', , { 'vl-modal__content--with-left-image': _vm.withLeftImage }],style:({
        marginTop: _vm.top + 'px',
        maxWidth: _vm.maxWidth + 'px',
        flexBasis: _vm.maxWidth + 'px',
        textAlign: _vm.contentAlign,
      }),on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"vl-modal__header"},[_c('div',{staticClass:"container-fluid px-0"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-24 d-flex justify-content-end align-items-center mb-3"},[(!_vm.outside)?_c('button',{staticClass:"vl-modal__cancel",on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_c('span')]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-24 d-flex align-items-center",style:({
                'justify-content': _vm.titleAlign === 'center' ? _vm.titleAlign : `${_vm.titleAlign}`,
              })},[(_vm.titleWithSuccessIcon)?_c('div',{staticClass:"vl-modal__img-wrapper"},[_c('img',{attrs:{"src":require('@javascript/packs/images/@privat/modal/icon-success.png'),"alt":"success icon"}})]):_vm._e(),_vm._v(" "),_c('h3',{staticClass:"vl-modal__title",style:({
                  textTransform: _vm.titleTransform,
                })},[_vm._t("header")],2)])])])]),_vm._v(" "),_c('div',{ref:"modalBody",class:['vl-modal__body', { scroll: _vm.staticScroll }],style:({
          marginTop: _vm.contentOutsideTop + 'px',
          marginBottom: _vm.contentOutsideBottom + 'px',
        })},[_c('div',{staticClass:"vl-modal__description"},[_vm._t("default")],2)]),_vm._v(" "),_c('div',{staticClass:"vl-modal__bottom",style:({
          marginTop: _vm.footerOffsetTop + 'px',
        })},[_vm._t("footer")],2)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }