var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'vl-btn',
    _vm.typeDefinition,
    _vm.size ? 'vl-btn--size-' + _vm.size : 'vl-btn--size-large',
    _vm.align ? 'vl-btn--align-' + _vm.align : '',
    _vm.textTransform ? 'vl-btn--text-transform-' + _vm.textTransform : '',
    _vm.isActive ? 'vl-btn--type-' + _vm.type + '-active' : '',
  ],style:({
    maxWidth: _vm.maxWidth + 'px',
    maxHeight: _vm.maxHeight + 'px',
    minWidth: _vm.minWidth + 'px',
    fontSize: _vm.fontSize + 'px',
  }),attrs:{"disabled":_vm.disabled,"type":_vm.actionType}},[(_vm.loading)?[_vm._m(0)]:_vm._e(),_vm._v(" "),[_vm._t("default")]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spiner-position spinner-border spinner-color",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }