<template>
  <div class="position-relative">
    <slot name="activator" v-bind="listeners" />

    <div class="d-none" ref="content">
      <slot />
    </div>
  </div>
</template>

<script>
const {
  document,
  document: { body },
} = window

const id = 'tooltip-container'

const container = Object.assign(document.createElement('div'), {
  id,
})

const btn = document.createElement('button')
btn.classList.add('tooltip-close')
btn.appendChild(document.createElement('span'))

export default {
  name: 'VlTooltip',
  inheritAttrs: false,
  computed: {
    listeners({ disabled, show, hide }) {
      if (disabled || !this.$slots.default) return {}

      return { mouseenter: show, mouseleave: hide }
    },
  },
  methods: {
    show({ target }) {
      const { $refs: { content: { childNodes = [] } = {} } = {}, calculate } = this

      container.textContent = ''

      if (this.isCloseBtn) {
        container.appendChild(btn)
        container.style.padding = '20px'
      }

      childNodes.forEach((node) => container.appendChild(node.cloneNode(true)))

      if (!document.getElementById(id)) body.appendChild(container)

      calculate(target)
    },

    closeOnBtn() {
      btn.addEventListener('click', () => {
        container.removeAttribute('style')
        this.setStyle({ display: 'none' })
      })
    },

    hide() {
      if (!this.isCloseBtn) {
        container.removeAttribute('style')
        this.setStyle({ display: 'none' })
      }
      this.closeOnBtn()
    },
    calculate(activator) {
      const { setStyle, minWidth, maxWidth } = this

      setStyle({ display: 'block', opacity: 0, minWidth, maxWidth })

      const [a, b, c] = [
        activator.getBoundingClientRect(),
        body.getBoundingClientRect(),
        container.getBoundingClientRect(),
      ]

      const style = { top: a.top - b.top, left: a.left + a.width / 2 - c.width / 2, right: null }

      if (a.top - c.height < 0) {
        style.top += a.height + 8
      } else {
        style.top -= c.height + 8
      }

      if (style.left <= 10) style.left = 10

      if (style.left + c.width > b.width) {
        style.left = null
        style.right = 10
      }

      setStyle({ opacity: 1, ...style })
    },
    setStyle(style = {}) {
      return Object.assign(
        (container || {}).style || {},
        Object.fromEntries(
          Object.entries(style).map(([key, value]) => [
            key,
            !value || typeof value === 'string' || ['opacity'].includes(key) ? value : value.toString().concat('px'),
          ])
        )
      )
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isCloseBtn: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: [String, Number],
      default: undefined,
    },
    maxWidth: {
      type: [String, Number],
      default: 320,
    },
  },
}
</script>
