<template>
  <button
    :class="[
      'vl-btn',
      typeDefinition,
      size ? 'vl-btn--size-' + size : 'vl-btn--size-large',
      align ? 'vl-btn--align-' + align : '',
      textTransform ? 'vl-btn--text-transform-' + textTransform : '',
      isActive ? 'vl-btn--type-' + type + '-active' : '',
    ]"
    :style="{
      maxWidth: maxWidth + 'px',
      maxHeight: maxHeight + 'px',
      minWidth: minWidth + 'px',
      fontSize: fontSize + 'px',
    }"
    :disabled="disabled"
    :type="actionType"
  >
    <template v-if="loading">
      <div class="spiner-position spinner-border spinner-color" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </template>
    <template>
      <slot />
    </template>
  </button>
</template>
<script>
export default {
  name: 'VlButton',
  props: {
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'large',
      validator: val => ['small', 'medium', 'large', 'inline', 'full'].includes(val),
    },
    align: {
      type: String,
      default: null,
      validator: val => ['left', 'center', 'right'].includes(val),
    },
    textTransform: {
      type: String,
      default: 'inherit',
      validator: val => ['lowercase', 'uppercase'],
    },
    maxWidth: {
      type: String,
      default: null,
    },
    maxHeight: {
      type: String,
      default: null,
    },
    minWidth: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: [Number, String],
      default: null,
    },
    actionType: {
      type: String,
      default: 'button',
    },
    isActive: Boolean,
  },
  computed: {
    typeDefinition() {
      if (this.disabled && !this.loading) {
        return 'vl-btn--type-disabled'
      } else if (this.loading && this.disabled) {
        return 'vl-btn--type-loading'
      }
      return this.type ? `vl-btn--type-${this.type}` : 'vl-btn--type-primary'
    },
  },
}
</script>
