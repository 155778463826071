<template>
  <div class="mobile-language-container__list">
    <a
      v-for="language in languageList"
      :key="language.key"
      :class="[{'is-current': checkCurrentLocale(language.key)},'mobile-language-container__list__item']"
      :href="path(language.key)"
    >{{ language.title | shortForm }}</a>
  </div>
</template>
<script>
export default {
  name: 'LanguageContainer',
  props: {
    row: {
      type: Boolean,
      default: false,
    },
    availableLocales: {
      type: String,
      default: null,
    },
    currentPath: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    availableLocalesData: null,
    currentPathData: null,
  }),
  filters: {
    shortForm(value) {
      return value.slice(0, 3)
    },
  },
  computed: {
    currentLanguageTitle() {
      return this.availableLocalesData[this.locale]
    },
    languageList() {
      return Object.entries(this.availableLocalesData).map(item => ({
        key: item[0],
        title: item[1],
      }))
    },
  },
  methods: {
    path(locale) {
      return this.currentPathData.find(item => item.locale === locale).path
    },
    checkCurrentLocale(locale) {
      return this.locale === locale
    },
  },
  created() {
    this.availableLocalesData = JSON.parse(this.availableLocales)
    this.currentPathData = JSON.parse(this.currentPath)
  },
}
</script>
