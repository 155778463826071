<template>
  <div class="basket-container basket">
    <cart-desktop
      class="d-none d-md-block"
      :locale="locale"
      :default-locale="defaultLocale"
      :user-id="userId"
      :cart-total-count="cartTotalCount"
      :base-url-with-locale="baseUrlWithLocale"
    />
    <cart-mobile
      class="d-md-none"
      :locale="locale"
      :default-locale="defaultLocale"
      :user-id="userId"
      :cart-total-count="cartTotalCount"
      :base-url-with-locale="baseUrlWithLocale"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CartDesktop from './CartDesktop'
import CartMobile from './CartMobile'

export default {
  name: 'Cart',
  components: {
    CartDesktop,
    CartMobile,
  },
  props: {
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    userId: {
      type: [String, Number],
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      cartTotalCount: 'cart/cartTotalCount',
    }),
  },
}
</script>
