<script>
import AppHeader from '@components/@priorbank/header'
import AppFooter from '@components/@priorbank/footer'
import NotificationsList from '@components/@priorbank/ui/vl-notification'
import setValidateMessages from '@javascript/plugins/localization/veeValidateMessage'

export default {
  name: 'App',
  provide: {
    locale: 'ru',
    defaultLocale: 'ru',
    baseUrlWithLocale: '',
    isGuest: true,
  },
  components: {
    AppHeader,
    AppFooter,
    NotificationsList,
    PersonalProfile: () =>
      import(
        '@javascript/pages/profile/personal-profile/priorbank/PersonalProfile'
      ),
    PersonalProfileEdit: () =>
      import(
        '@javascript/pages/profile/personal-profile-edit/priorbank/PersonalProfileEdit'
      ),
    PersonalMyBalance: () =>
      import(
        '@javascript/pages/profile/personal-my-balance/priorbank/PersonalMyBalance'
      ),
    RegistrationDetails: () =>
      import(
        '@javascript/pages/registration/details/priorbank/RegistrationDetails'
      ),
    PersonalCards: () =>
      import(
        '@javascript/pages/profile/personal-cards/priorbank/PersonalCards'
      ),
    PersonalAddCard: () =>
      import(
        '@javascript/pages/profile/personal-add-card/priorbank/PersonalAddCard'
      ),
    PersonalOrders: () =>
      import(
        '@javascript/pages/profile/personal-my-orders/priorbank/PersonalMyOrders'
      ),
    AuthorizationLogin: () =>
      import('@javascript/pages/authorization/login/priorbank/Login.vue'),
    PersonalChangePassword: () =>
      import(
        '@javascript/pages/profile/personal-change-password/priorbank/PersonalChangePassword'
      ),
    ForgotPasswordOption: () =>
      import(
        '@javascript/pages/restoration/forgot-password/reset-options/priorbank/ForgotPasswordOptions'
      ),
    VerificationCode: () =>
      import(
        '@javascript/pages/restoration/forgot-password/verification-code/priorbank/VerificationCode'
      ),
    CreateNewPassword: () =>
      import(
        '@javascript/pages/restoration/forgot-password/new-password/priorbank/NewPassword'
      ),
    EmailVerifying: () =>
      import(
        '@javascript/pages/layouts/priorbank/email-verifying/EmailVerifying'
      ),
    OrderSuccess: () =>
      import('@javascript/pages/order/success/priorbank/OrderSuccess'),
    OrderPage: () => import('@javascript/pages/order/show/priorbank/Order'),
    CatalogPage: () => import('@javascript/pages/catalog/priorbank/Catalog'),
    ProductPage: () => import('@javascript/pages/product/priorbank/ProductPage'),
    ProductAddress: () =>
      import('@javascript/pages/product-address/priorbank/ProductAddress'),
    PersonalMyWishList: () =>
      import(
        '@javascript/pages/profile/personal-my-wish-list/priorbank/PersonalMyWishList'
      ),
    PersonalChangeEmail: () =>
      import(
        '@javascript/pages/profile/personal-change-email/priorbank/PersonalChangeEmail'
      ),
    MainPage: () => import('@javascript/pages/main/priorbank/Main'),
    SessionPopup: () =>
      import('@javascript/pages/layouts/priorbank/session-popup/SessionPopup'),
    CashBack: () => import('@javascript/pages/cashback/priorbank/CashBack'),
    CashBackShow: () =>
      import('@javascript/pages/cashback/priorbank/CashBackShow'),
    VlPopup: () => import('@javascript/pages/profile/personal-profile/popup'),
  },
  methods: {
    setLocalesSeting() {
      this._provided.locale = this.$el.attributes.locale.value
      this._provided.defaultLocale = this.$el.attributes.defaultLocale.value
      this._provided.baseUrlWithLocale = this.$el.attributes.base_url_with_locale.value
      this._provided.isGuest = !JSON.parse(this.$el.attributes.is_guest.value)
      setValidateMessages(this._provided.locale)
    },
  },
  mounted() {
    this.setLocalesSeting()
  },
}
</script>
