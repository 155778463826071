<template>
  <div class="menu-desctop-button">
    <menu-desktop-button-logged-in
      v-if="isLogin"
      :locale="locale"
      :user-name="userName"
      :profile-menu="profileMenu"
      :phones="phones"
    />
    <vl-button
      v-else
      class="h-100 header__login-button"
      type="secondary"
      size="medium"
      @click.native.stop="openRegistrationPage"
    >
      <span>{{t[locale]['priorbank.header.header_top_menu.register']}}</span>
    </vl-button>
  </div>
</template>
<script>
import VlButton from '@components/@exim/ui/vl-button'
import MenuDesktopButtonLoggedIn from './MenuDesktopButtonLoggedIn'
import { mapActions } from 'vuex'
import { REGISTRATION_CARD } from '@javascript/config/routes'
import { redirectTo } from '@javascript/utils/helper-methods'
import locales from '../locales'

export default {
  name: 'MenuButton',
  components: {
    VlButton,
    MenuDesktopButtonLoggedIn,
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: false,
    },
    profileMenu: {
      type: Array,
      default: null,
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    phones: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    t: locales,
  }),
  methods: {
    ...mapActions({
      API_LOGOUT: 'authorization/API_LOGOUT',
    }),
    openRegistrationPage() {
      let link = `${this.baseUrlWithLocale}${REGISTRATION_CARD}`
      redirectTo(link)
    },
  },
}
</script>
