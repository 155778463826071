<template>
  <div class="footer-middle container">
    <div class="row">
      <div class="col-12 col-md-8 footer-middle__item">
        <p class="title">{{t[locale]['priorbank.footer.prior_plus_menu.title']}}</p>
        <ul class="nav-menu" v-if="firstMenu">
          <li v-for="(item, index) in firstMenu.children" :key="index">
            <a :href="item.url" target="_blank" rel="noopener noreferrer">{{ item.title }}</a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-8 footer-middle__item">
        <p class="title">{{t[locale]['priorbank.footer.information_menu.title']}}</p>
        <ul class="nav-menu" v-if="secondMenu">
          <li v-for="(item, index) in secondMenu" :key="index">
            <a :href="item.url" target="_blank" rel="noopener noreferrer">{{ item.title }}</a>
          </li>
        </ul>
      </div>
      <div class="col-24 col-md-8 mt-3 mt-md-0 footer-middle__item">
        <p class="title">{{t[locale]['priorbank.footer.help_menu.title']}}</p>
        <p class="email">{{email}}</p>
        <vl-button
          @click.native="toggleCallBackFormModal(true)"
          size="medium"
          type="primary"
        >{{t[locale]['priorbank.footer.help_menu.button_name']}}</vl-button>
      </div>
    </div>
    <vl-modal
      :open="visibleCallBackForm"
      @close="toggleCallBackFormModal(false)"
      title-transform="none"
      max-width="780"
    >
      <template #header>
        {{t[locale]['modals.call_back.title_part1']}}
        <br />
        {{t[locale]['modals.call_back.title_part2']}}
      </template>
      <template #default>
        <call-back-form :locale="locale" @closeModall="toggleCallBackFormModal(false)" />
      </template>
    </vl-modal>
  </div>
</template>
<script>
import VlModal from '@components/@priorbank/ui/vl-modal'
import CallBackForm from '../call-back-form/CallBackForm'
import VlButton from '@components/@priorbank/ui/vl-button'
import locales from '../locales'
export default {
  name: 'FooterMiddle',
  components: { VlModal, CallBackForm, VlButton },
  props: {
    firstMenu: {
      type: [Array, Object],
      default: null,
    },
    secondMenu: {
      type: [Array, Object],
      default: null,
    },
    email: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      t: locales,
      visibleCallBackForm: false,
    }
  },
  methods: {
    toggleCallBackFormModal(value) {
      this.visibleCallBackForm = value
    },
  },
}
</script>
