import Vue from 'vue'
import moment from 'moment'
import PortalVue from 'portal-vue'
import veeValidate from '@javascript/plugins/localization/veeValidate'
import store from '@javascript/vuex/store'
import App from './App'
import '../../stylesheets/priorbank/application.sass'
import VlNotificationStatic from '@components/@priorbank/ui/vl-notification-static'
import filters from '@javascript/filters/filters'
import clickOutside from '@javascript/directives/click-outside'
import vueAwesomeCountdown from 'vue-awesome-countdown'

Vue.prototype.moment = moment

Vue.use(PortalVue)

Vue.config.ignoredElements = [
  'g:savetoandroidpay',
]

Vue.component('vlNotification', VlNotificationStatic)
Vue.use(vueAwesomeCountdown)

for (let filter in filters) {
  Vue.filter(filter, filters[filter])
}

Vue.directive('click-outside', clickOutside)

Vue.prototype.$bus = new Vue()

new Vue({
  el: '#app',
  store,
  mixins: [App],
})
require('@rails/ujs').start()

window.onerror = function(errorMessage, errorUrl, errorLine) {
  const data = {
    userAgent: navigator.userAgent,
    errorMessage, 
    errorUrl, 
    errorLine
  } 
  
  fetch('/error_report', {
    method: 'POST',
    body: JSON.stringify(data), 
    headers: {
      'Content-Type': 'aplication/json'
    }
  })

  return true
}