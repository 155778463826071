<template>
  <div class="call-back-form">
    <validation-observer ref="form">
      <form @submit.prevent="checkValidForm">
        <div class="mb-3">
          <validation-provider
            rules="required|min:2"
            v-slot="{ errors, failed }"
            vid="first_name"
          >
            <vl-input-text
              v-model="formData.name"
              :error="failed"
              type="text"
              :label="t[locale]['forms.you_first_name']"
            />
            <span class="error-message">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="mb-3">
          <validation-provider
            rules="required|email"
            v-slot="{ errors, failed }"
            vid="email"
          >
            <vl-input-text
              v-model="formData.email"
              :error="failed"
              type="email"
              :label="t[locale]['forms.your_email']"
            />
            <span class="error-message">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="mb-3">
          <vl-input-text
            v-model="formData.topic"
            type="text"
            :label="t[locale]['forms.topic_message']"
          />
        </div>
        <div class="mb-4">
          <validation-provider
            rules="required|min:8"
            v-slot="{ errors, failed }"
            vid="text_message"
          >
            <vl-textarea
              v-model="formData.message"
              :error="failed"
              :label="t[locale]['forms.text_message']"
            />
            <span class="error-message">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="edit-button">
          <vl-button
            type="primary"
            size="large"
            actionType="submit"
            :loading="loadingButton"
            >{{ t[locale]['modals.call_back.button_name'] }}</vl-button
          >
        </div>
      </form>
    </validation-observer>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import VlInputText from '@components/@priorbank/ui/vl-input-text'
import VlButton from '@components/@priorbank/ui/vl-button'
import VlTextarea from '@components/@priorbank/ui/vl-textarea'
import locales from './locales'
export default {
  name: 'CallBackForm',
  components: {
    VlInputText,
    VlButton,
    VlTextarea,
  },
  props: {
    locale: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      t: locales,
      formData: {
        name: '',
        email: '',
        topic: '',
        message: '',
      },
      loadingButton: false,
    }
  },
  methods: {
    ...mapActions({
      API_SEND_MAIL: 'profile/API_SEND_MAIL',
    }),
    ...mapMutations({
      showSuccessNotification: 'notification/successNotification',
      showErrorNotification: 'notification/errorNotification',
    }),
    checkValidForm() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        this.sendMessage()
      })
    },
    async sendMessage() {
      this.changeButtonStatus(true)
      try {
        const response = await this.API_SEND_MAIL(this.formData)
        if (response.status === 200) {
          this.showSuccessNotification({
            description: this.t[locale]['notifications.success_mail_send'],
            permanent: false,
            timeout: 3000,
          })
          this.$emit('closeModall')
        }
      } catch (e) {
        if (e.response.status >= 500) {
          this.showErrorNotification({
            description: this.t[locale]['notifications.server_error'],
            permanent: false,
            timeout: 3000,
          })
        } else {
          this.showErrorNotification({
            description: this.t[locale]['notifications.error_mail_send'],
            permanent: false,
            timeout: 3000,
          })
        }
      } finally {
        this.changeButtonStatus(false)
      }
    },
    changeButtonStatus(value) {
      this.loadingButton = value
    },
  },
}
</script>
