export default {
  data: () => ({
    isPlaceholder: false,
    hasFocus: false,
  }),
  methods: {
    blur() {
      this.hasFocus = false
    },
    focus(evt) {
      this.hasFocus = true
      this.$emit('focus', evt)
    },
  },
  computed: {
    isFilled() {
      // return String(this.value) && !this.hasFocus
      return this.value && !this.hasFocus
    },
    isPlaceHolder() {
      return !this.value && this.placeholder && !this.hasFocus
    },
    isActive() {
      return this.hasFocus
    },
    isActiveLabel() {
      return this.isFilled || this.isPlaceHolder || this.isActive
    },
  },
}
