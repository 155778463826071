<template>
  <div
    class="header-bottom"
    :class="[
      { 'not-login': !isLogin },
      { 'is-login-page': hiddenOnThisPages },
      { 'is-login-page_catalog': isCatalogPage && isLogin },
    ]"
  >
    <div class="container">
      <div
        class="row justify-content-between flex-nowrap no-gutters align-items-center"
      >
        <bottom-menu-nav
          :is-login="isLogin"
          :base-url-with-locale="baseUrlWithLocale"
          :locale="locale"
          :is-show="showBottomMenu"
          @clickHandler="clickMenuHandler"
        />
        <bottom-menu-logged-in
          :is-login="isLogin"
          v-if="isLogin"
          :locale="locale"
          :default-locale="defaultLocale"
          :base-url-with-locale="baseUrlWithLocale"
          :user-data="userData"
          :phones="phones"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import {
  REGISTRATION_CARD,
  REGISTRATION_DETAILS,
  AUTHORIZATION_LOGIN,
  REGISTRATION_FAILURE,
  CATALOG,
} from '@javascript/config/routes'
import { scrollToElementById } from '@javascript/utils/helper-methods'
import BottomMenuLoggedIn from './menus/bottom-menu-logged-in/BottomMenuLoggedIn'
import BottomMenuNav from './menus/bottom-menu-nav/BottomMenuNav'
import locales from './locales'

export default {
  name: 'HeaderBottom',
  components: {
    BottomMenuLoggedIn,
    BottomMenuNav,
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      default: null,
    },
    userName: {
      type: String,
      default: false,
    },
    profileMenu: {
      type: Array,
      default: null,
    },
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    providerId: {
      type: [String, Number],
      default: null,
    },
    phones: {
      type: Array,
      default: () => ([]),
    },
    isHomePage: {
      type: String,
    },
  },
  computed: {
    hiddenOnThisPages() {
      let pathName = window.location.pathname
      // console.log(pathName)
      return (
        pathName.includes(REGISTRATION_CARD) ||
        pathName.includes(REGISTRATION_DETAILS) ||
        pathName.includes(AUTHORIZATION_LOGIN) ||
        pathName.includes(REGISTRATION_FAILURE) ||
        pathName.includes(CATALOG)
      )
    },
    isCatalogPage() {
     return CATALOG === window.location.pathname || window.location.pathname.includes('catalog')
    },
    showBottomMenu() {
      // return !this.hiddenOnThisPages
      return JSON.parse(this.isHomePage)
    },
    isAuthorizationLoginPage() {
      if (AUTHORIZATION_LOGIN === window.location.pathname) {
        return true
      }
      return false
    },
  },
  methods: {
    clickMenuHandler({ value }) {
      scrollToElementById(value)
    },
  },
}
</script>
