<template>
  <div class="container footer-top">
    <div class="row align-items-end">
      <div
        class="col-24 col-md-8 footer-top__item d-flex justify-content-start align-items-start"
      >
        <div>
          <a class="value" :href="`tel:${callCenterPhone}`">
            {{ callCenterPhone }}
          </a>
          <p class="title">{{ t[locale]['priorbank.footer.call_centre'] }}</p>
        </div>
      </div>
      <div class="col-24 col-md-8 mt-3 mt-md-0 footer-top__item">
        <div class="d-flex align-items-center">
          <div class="icon-phone">
            <vl-icon-base
              :width="20"
              :height="30"
              :viewBoxWidth="20"
              :viewBoxHeight="30"
              iconColor="#333"
            >
              <vl-icon-phone />
            </vl-icon-base>
          </div>
          <a class="value support-phone" href="tel:+375172899292">+375 17 289-92-92</a>
        </div>
        <p class="title">{{ t[locale]['priorbank.footer.support'] }}</p>
      </div>
      <div class="col-24 col-md-8 mt-3 mt-md-0 footer-top__item">
        <div>
          <a class="value" href="tel:+375297299090">+375297299090</a>
        </div>
        <div>
          <a class="value" href="tel:+375447299090">+375447299090</a>
        </div>
        <div>
          <a class="value" href="tel:+375257299090">+375257299090</a>
        </div>
        <p class="title">{{ t[locale]['priorbank.footer.operators'] }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import VlIconBase from '@components/@priorbank/ui/vl-icons'
import VlIconPhone from '@components/@priorbank/ui/vl-icons/VlIconPhone'
import locales from '../locales'
export default {
  name: 'FooterContacts',
  components: {
    VlIconBase,
    VlIconPhone,
  },
  props: {
    phones: {
      type: Array,
      default: [],
    },
    email: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      t: locales,
    }
  },
  computed: {
    callCenterPhone() {
      if (Array.isArray(this.phones) && this.phones[0]) {
        return this.phones[0]
      }
      return ''
    },
    supportPhone() {
      if (Array.isArray(this.phones) && this.phones[1]) {
        return this.phones[1]
      }
      return ''
    },
    mobilePhone() {
      if (Array.isArray(this.phones) && this.phones[2]) {
        return this.phones[2]
      }
      return ''
    },
  },
}
</script>
